var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  $(document).on('inv_status_data:updated', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list', $(this));
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $statusList = $('.js-inv-status-list', $(this));

    if ($statusList.length < 1) {
      return null;
    }
    Drupal.behaviors.inventoryStatusV1.selectSku($statusList, skuBaseId);
  });

  $(document).on('product.init', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list', $(this));
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData && skuData.DEFAULT_SKU === 1) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  });

  $(document).on('product.allSkuValidate', '.js-product', function () {
    var $statusList = $('.js-inv-status-list', this);

    Drupal.behaviors.inventoryStatusV1.allSkuValidate($statusList);
  });

  Drupal.behaviors.inventoryStatusV1 = {
    displaySkuStatus: !!Drupal.settings?.common?.display_sku_status,

    update: function ($statusList, skuData) {
      var skuBaseId = $statusList.data('sku-base-id');

      if (!skuData || !skuData.INVENTORY_STATUS) {
        return null;
      }
      if (skuData.SKU_ID !== 'SKU' + skuBaseId) {
        return null;
      }

      $('li', $statusList).hide();
      var $statusToShow = $('.js-inv-status-' + skuData.INVENTORY_STATUS, $statusList);

      if (this.displaySkuStatus) {
        var $skuStatusToShow = $('.js-sku-status-' + skuData.SKU_STATUS, $statusList);
      }

      if ($statusToShow.length > 0 || this.displaySkuStatus) {
        if (!(skuData.INVENTORY_STATUS == 7 && skuData.SKU_STATUS == 6)) {
          $statusToShow.show();
        }
        if (this.displaySkuStatus && $skuStatusToShow.length > 0) {
          $skuStatusToShow.show();
        }
        // To Remove Sold out product from custom MPP page only.
        if ($('.mpp-container--custom').length > 0) {
          if (
            skuData.INVENTORY_STATUS === 7 &&
            skuData.SKU_STATUS !== 6 &&
            !Drupal.settings.common.show_toos_soldout_message_mpp
          ) {
            var $statusToShow_mpp = $('.js-inv-status-' + skuData.INVENTORY_STATUS, $statusList);

            $statusToShow_mpp.parents('.product-grid__item').remove();
            var size = Unison.fetch.now().name;

            if (Drupal.behaviors && Drupal.behaviors.quickshopOverlay) {
              Drupal.behaviors.quickshopOverlay.initializeGrid($('.js-product-grid'), size);
            }
          }
        }
        $statusList.trigger('inv_status_display:updated');
      }
      var displayToos = Drupal.settings.common && Drupal.settings.common.enable_toos;
      var displayComing = Drupal.settings.common && Drupal.settings.common.enable_coming_soon;

      if (displayToos && skuData.INVENTORY_STATUS == 2) {
        $(document).trigger('product.waitlist.init', skuData);
      } else if (displayComing && skuData.INVENTORY_STATUS == 3) {
        $(document).trigger('product.waitlist.init', skuData);
      } else if (!(skuData.INVENTORY_STATUS == 7)) {
        $(document).trigger('product.waitlist.reset', skuData);
      }
      if (skuData.INVENTORY_STATUS != 1) {
        $('.js-add-to-cart')
          .filter('[data-product-id="' + skuData.PRODUCT_ID + '"]')
          .addClass('button--disabled')
          .attr('disabled', 'disabled');
      }

      var hasWaitlist = Drupal.settings.common && Drupal.settings.common.has_waitlist;

      if (hasWaitlist) {
        // To display notify me button for only sold out product.
        if (skuData.INVENTORY_STATUS == 7 || skuData.INVENTORY_STATUS == 3) {
          $(document).trigger('product.waitlist.init', skuData);
        } else {
          $(document).trigger('product.waitlist.reset', skuData);
        }
      }
    },

    selectSku: function ($statusList, skuBaseId) {
      $statusList.data('sku-base-id', skuBaseId);
      var skuData = prodcat.data.getSku(skuBaseId);

      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    },
    allSkuValidate: function ($statusList) {
      // Validate SKUs are Soldout / TOOS condition
      var prodData = prodcat.data.getProduct($statusList.data('product-id'));
      var $slodoutStatus = $('.js-inv-status-7', $statusList);
      var $toosStatus = $('.js-inv-status-2', $statusList);
      var count_toos;

      if (!prodData) {
        return;
      }

      // All SKUs are TOOS
      count_toos = jQuery.grep(prodData.skus, function (sku) {
        return sku.INVENTORY_STATUS === 2;
      });

      prodData.skus.length === count_toos.length ? $toosStatus.show() : $toosStatus.hide();
      // All SKUs are Soldout
      var count_sold_out = jQuery.grep(prodData.skus, function (sku) {
        return sku.INVENTORY_STATUS === 7;
      });

      prodData.skus.length === count_sold_out.length
        ? $slodoutStatus.show()
        : $slodoutStatus.hide();
      // Mixed Soldout + Toos
      var total = count_sold_out.length + count_toos.length;

      if (total === prodData.skus.length) {
        $('.js-inv-status-' + prodData.defaultSku.INVENTORY_STATUS, $statusList).show();
      }
    }
  };
})(jQuery);
